
.paper-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .paper-admin-header{
    display: flex;
    justify-content: space-between;
    ::v-deep .el-input{
      .el-input-group__append{
        padding-right: 10px;
        box-sizing: border-box;
        height: 40px;
        //background: #2DC079;
        color: #fff;
        font-size: 16px;
        .el-button{
          height: 40px;
          border-radius: 0 4px 4px 0;
          padding: 0 8px;
          background: #1AB163;
          &:hover{
            background: #2DC079;
          }
        }
      }
    }
  }
}
